import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Product } from "../../../shared-components/models/product";
import { StockUtils } from "../../../shared-components/utils/stock-utils";
import { EStockStatus } from "../../../shared-components/models/enumeration/e-stock-status";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { WishlistService } from "../../../services/wishlist.service";
import { CartService } from "../../../services/cart.service";
import { UrlUtils } from "../../../utils/url-utils";
import { mergeMap, Subscription } from "rxjs";

@Component({
	selector: 'app-product',
	templateUrl: './product.component.html',
	styleUrl: './product.component.css'
})
export class ProductComponent implements OnInit, OnDestroy {
	@Input() product?: Product;

	public isMobile: boolean;
	public isAlreadyAdded?: boolean;

	private wishlistSubscription?: Subscription;
	private alreadyAddedSubscription?: Subscription;
	private dataChangeSubscription?: Subscription;
	private cartSubscription?: Subscription;

	constructor(
		private breakpointObserver: BreakpointObserver,
		private wishlistService: WishlistService,
		private cartService: CartService,
	) {
		this.isMobile = false;
	}

	ngOnInit(): void {
		this.breakpointObserver.observe(Breakpoints.XSmall)
			.subscribe({ next: result => this.isMobile = result.matches });

		this.wishlistService.getDataChanges()
			.subscribe({
				next: productId => {
					if (this.product?.id === productId) {
						this.isAlreadyAdded = !this.isAlreadyAdded;
					}
				}
			})

		this.alreadyAddedSubscription = this.wishlistService.isProductAlreadyAdded(this.product?.id)
			.subscribe({
				next: alreadyAdded => this.isAlreadyAdded = alreadyAdded
			})
	}

	ngOnDestroy() {
		this.wishlistSubscription?.unsubscribe();
		this.cartSubscription?.unsubscribe();
		this.dataChangeSubscription?.unsubscribe();
		this.alreadyAddedSubscription?.unsubscribe();
	}

	public getProductLink = (product: Product): string => {
		return `/product/${ product.id }/${ UrlUtils.sanitizeUrl(product.name) }`;
	}

	public getSalePercentage = (product: Product): string => {
		// @ts-ignore
		let percentage = ((product.listPrice - product.salePrice) / product.listPrice) * 100;
		return `-${ percentage.toFixed(0) }%`;
	}

	public addToWishlist = (e: Event, product: Product) => {
		e.stopPropagation();

		if (product) {
			this.wishlistSubscription = this.wishlistService.isProductAlreadyAdded(product.id)
				.pipe(
					mergeMap(isAlreadyAdded => isAlreadyAdded
						? this.wishlistService.removeProduct(product.id)
						: this.wishlistService.storeProduct(product.id)
					)
				)
				.subscribe();
		}
	}

	public handleClick = (product: Product) => {
		if (product && product.stock > 0) {
			this.cartSubscription = this.cartService.storeProduct(product)
				.subscribe();
		}
	}

	protected readonly StockUtils = StockUtils;
	protected readonly EStockStatus = EStockStatus;
}
