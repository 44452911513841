import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Meta, Title } from "@angular/platform-browser";
import { metaTags } from "./meta-tags";
import { LoadingService } from "./shared-components/services/loading.service";
import { NavigationEnd, Router } from "@angular/router";

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrl: './app.component.css'
})
export class AppComponent implements OnInit {
	public isLoading?: boolean;
	public shouldShowHeaderAndFooter?: boolean;

	constructor(
		private readonly titleService: Title,
		private readonly metaService: Meta,
		private loadingService: LoadingService,
		private changeDetectorRef: ChangeDetectorRef,
		private router: Router,
	) {
		this.titleService.setTitle('Caveaugames');
		metaTags.forEach(tag => this.metaService.addTag(tag));
	}

	ngOnInit() {
		this.loadingService.isLoading()
			.subscribe({
				next: value => {
					this.isLoading = value;
					this.changeDetectorRef.detectChanges();
				}
			});

		this.router.events
			.subscribe({
				next: value => {
					if (value instanceof NavigationEnd) {
						this.shouldShowHeaderAndFooter = value.url.includes('/login') || value.url.includes('/register');
					}
				}
			});
	}
}
