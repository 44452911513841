import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { WishlistService } from "../../../services/wishlist.service";
import { mergeMap, Subscription } from "rxjs";

@Component({
	selector: 'app-wishlist-button',
	templateUrl: './wishlist-button.component.html',
	styleUrl: './wishlist-button.component.css'
})
export class WishlistButtonComponent implements OnInit, OnDestroy {
	@Input() productId?: number;
	public isAlreadyAdded?: boolean;

	private wishlistSubscription?: Subscription;
	private alreadyAddedSubscription?: Subscription;
	private dataChangeSubscription?: Subscription;

	constructor(private wishlistService: WishlistService) {
	}

	ngOnInit(): void {
		this.alreadyAddedSubscription = this.wishlistService.isProductAlreadyAdded(this.productId)
			.subscribe({
				next: alreadyAdded => this.isAlreadyAdded = alreadyAdded
			});
		this.dataChangeSubscription = this.wishlistService.getDataChanges()
			.subscribe({
				next: productId => {
					if (this.productId === productId) {
						this.isAlreadyAdded = !this.isAlreadyAdded;
					}
				}
			});
	}

	ngOnDestroy(): void {
		this.wishlistSubscription?.unsubscribe();
		this.alreadyAddedSubscription?.unsubscribe();
		this.dataChangeSubscription?.unsubscribe();
	}

	public manageWishlist = (): void => {
		if (!this.productId) {
			return;
		}

		this.wishlistSubscription = this.wishlistService.isProductAlreadyAdded(this.productId)
			.pipe(
				mergeMap(isAlreadyAdded => isAlreadyAdded
					? this.wishlistService.removeProduct(this.productId ?? 0)
					: this.wishlistService.storeProduct(this.productId ?? 0)
				)
			)
			.subscribe();
	}
}
