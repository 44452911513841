import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { mergeMap } from "rxjs";
import { PaymentService } from "../../service/payment.service";

@Component({
	selector: 'app-payment-status',
	templateUrl: './payment-status.component.html',
	styleUrl: './payment-status.component.css'
})
export class PaymentStatusComponent implements OnInit {
	public isPaymentSuccessful?: boolean;

	constructor(private route: ActivatedRoute, private paymentService: PaymentService) {
	}

	ngOnInit() {
		this.route.queryParams
			.pipe(
				mergeMap(params => this.paymentService.getPayment(params['payment_intent']))
			)
			.subscribe({
				next: payment => {
					this.isPaymentSuccessful = payment.status === 'succeeded';
				}
			});
	}

}
