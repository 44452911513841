@if (formGroup) {
	<div class="container">
		<div class="content-wrapper">
			<div class="stepper-container">
				<app-page-stepper [steps]="steps"/>
			</div>
			<div class="sections-wrapper">
				<div class="form">
					<app-payment-form [formGroup]="formGroup" [countries]="countries"/>
				</div>

				<div class="order">
					<app-order-recap [formGroup]="formGroup" [cartProducts]="cartProducts"/>
				</div>
			</div>
		</div>
	</div>
}