import { Component, Input, OnDestroy } from '@angular/core';
import { Product } from "../../models/product";
import { CartService } from "../../../services/cart.service";
import { UrlUtils } from "../../../utils/url-utils";
import { Subscription } from "rxjs";

@Component({
	selector: 'app-product-card',
	templateUrl: './product-card.component.html',
	styleUrl: './product-card.component.css'
})
export class ProductCardComponent implements OnDestroy {
	@Input() product?: Product;

	private cartSubscription?: Subscription;

	constructor(private cartService: CartService) {
	}

	ngOnDestroy() {
		this.cartSubscription?.unsubscribe();
	}

	public getSalePercentage = (product: Product): string => {
		// @ts-ignore
		let percentage = ((product.listPrice - product.salePrice) / product.listPrice) * 100;
		return `-${ percentage.toFixed(0) }%`;
	}

	public addToCart = (product: Product) => {
		this.cartSubscription = this.cartService.storeProduct(product)
			.subscribe();
	}

	public getProductLink = (product: Product): string => {
		return `/product/${ product.id }/${ UrlUtils.sanitizeUrl(product.name) }`;
	}
}
