import { Component, Input, OnInit, signal, ViewChild, WritableSignal } from '@angular/core';
import { StripeElementsOptions, StripePaymentElementOptions } from '@stripe/stripe-js';
import { injectStripe, StripePaymentElementComponent, StripeServiceInterface } from "ngx-stripe";
import { PaymentService } from "../../service/payment.service";
import { fromPromise } from "rxjs/internal/observable/innerFrom";
import { CartService } from "../../../services/cart.service";
import { mergeMap } from "rxjs";
import { FormGroup } from "@angular/forms";
import { Country } from "../../models/country";
import { Province } from "../../models/province";
import { CouponService } from "../../../shared-components/services/coupon.service";
import { getFormControlValue } from "../../../utils/form-utils";
import { environment } from "../../../../environments/environment";

@Component({
	selector: 'app-payment',
	templateUrl: './payment.component.html',
	styleUrl: './payment.component.css'
})
export class PaymentComponent implements OnInit {
	@Input() formGroup?: FormGroup;
	@ViewChild(StripePaymentElementComponent) paymentElement!: StripePaymentElementComponent;

	public stripe: StripeServiceInterface;
	public elementsOptions: StripeElementsOptions;
	public paymentOptions: StripePaymentElementOptions;
	public isPaying: WritableSignal<boolean>;

	constructor(
		private cartService: CartService,
		private paymentService: PaymentService,
		private couponService: CouponService
	) {
		this.stripe = injectStripe();
		this.elementsOptions = {
			locale: 'it',
			appearance: {
				theme: 'stripe'
			},
			paymentMethodCreation: 'manual'
		};
		this.paymentOptions = {
			layout: {
				type: 'accordion',
				defaultCollapsed: false,
				radios: false,
				spacedAccordionItems: true
			}
		};

		this.isPaying = signal(false);
	}

	ngOnInit() {
		this.cartService.getCartCost()
			.pipe(
				mergeMap(cartCosts => this.paymentService.createPaymentIntent({
						amount: cartCosts.total,
						currency: 'eur',
						shippingCost: '',
						apartment: this.formGroup?.get('apartment')?.value,
						billingApartment: this.formGroup?.get('billingApartment')?.value,
						coupon: this.couponService.getAppliedCoupon()?.couponCode,
						note: this.formGroup?.get('note')?.value
					})
				)
			)
			.subscribe({
				next: intentResponse => this.elementsOptions.clientSecret = intentResponse.clientSecret ?? ''
			});
	}

	public pay = () => {
		if (this.isPaying()) {
			return;
		}

		this.isPaying.set(true);

		fromPromise(this.paymentElement.elements.submit())
			.subscribe({
				next: _ => {
					this.setBillingData();
					this.stripe.confirmPayment({
						elements: this.paymentElement.elements,
						confirmParams: {
							payment_method_data: {
								billing_details: {
									name: this.getFullName(true),
									email: this.getFormControlValue<string>('email'),
									address: {
										city: this.getFormControlValue<string>('billingCity'),
										country: this.getFormControlValue<Country>('billingCountry')?.code,
										line1: this.getFormControlValue<string>('billingAddress'),
										line2: this.getFormControlValue<Province>('billingProvince')?.name,
										postal_code: this.getFormControlValue('billingZipCode')
									},
									phone: this.getFormControlValue<string>('billingPhone')
								}
							},
							shipping: {
								name: this.getFullName(false),
								address: {
									city: this.getFormControlValue<string>('city'),
									country: this.getFormControlValue<Country>('country')?.code,
									line1: this.getFormControlValue<string>('address') ?? '',
									line2: this.getFormControlValue<Province>('province')?.name,
									postal_code: this.getFormControlValue<string>('zipCode')
								},
								phone: this.getFormControlValue<string>('phone')
							},
							return_url: `${ environment.baseUrl }/payment-status`,
						},
						redirect: 'always',
						clientSecret: this.elementsOptions.clientSecret ?? ''
					})
						.subscribe({
							next: _ => this.isPaying.set(false)
						});
				}
			});
	}

	private getFullName = (isBilling: boolean): string => {
		return isBilling
			? `${ this.getFormControlValue<string>('billingFirstName') } ${ this.getFormControlValue<string>('billingLastName') }`
			: `${ this.getFormControlValue<string>('firstName') } ${ this.getFormControlValue<string>('lastName') }`;
	}

	private getFormControlValue = <T>(key: string): T | undefined => {
		return getFormControlValue<T>(this.formGroup, key);
	}

	private setBillingData = (): void => {
		if (this.getFormControlValue<boolean>('sameAddress')) {
			this.formGroup?.patchValue({
				billingFirstName: this.getFormControlValue<string>('firstName'),
				billingLastName: this.getFormControlValue<string>('lastName'),
				billingCity: this.getFormControlValue<string>('city'),
				billingCountry: this.getFormControlValue<Country>('country'),
				billingProvince: this.getFormControlValue<Province>('province'),
				billingZipCode: this.getFormControlValue<string>('zipCode'),
				billingPhone: this.getFormControlValue<string>('phone')
			});
		}
	}
}
