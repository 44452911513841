import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrdersComponent } from './components/orders/orders.component';
import { AccountComponent } from './views/account/account.component';
import { SharedComponentsModule } from "../shared-components/shared-components.module";
import { AccountDashboardComponent } from "./components/account-dashboard/account-dashboard.component";
import { AddressesComponent } from './components/addresses/addresses.component';
import { EditAddressComponent } from './components/edit-address/edit-address.component';
import { AccountDetailsComponent } from './components/account-details/account-details.component';


@NgModule({
	declarations: [
		AccountComponent,
		OrdersComponent,
		AccountDashboardComponent,
		AddressesComponent,
		EditAddressComponent,
		AccountDetailsComponent
	],
	imports: [
		CommonModule,
		SharedComponentsModule
	]
})
export class AccountModule {
}
