import { Component, OnDestroy, OnInit } from '@angular/core';
import { WishlistService } from "../../../services/wishlist.service";
import { Product } from "../../../shared-components/models/product";
import { ProductService } from "../../../category/services/product.service";
import { mergeMap, of, Subscription } from "rxjs";
import { Wishlist } from "../../../models/wishlist";

@Component({
	selector: 'app-wishlist',
	templateUrl: './wishlist.component.html',
	styleUrl: './wishlist.component.css'
})
export class WishlistComponent implements OnInit, OnDestroy {
	public products?: Product[];
	private productsSubscription?: Subscription;
	private dataChangeSubscription?: Subscription;

	constructor(private wishlistService: WishlistService, private productService: ProductService) {
	}

	ngOnInit(): void {
		this.retrieveProducts();
		this.dataChangeSubscription = this.wishlistService.getDataChanges()
			.subscribe({
				next: _ => {
					this.retrieveProducts();
				}
			});
	}

	ngOnDestroy() {
		this.productsSubscription?.unsubscribe();
		this.dataChangeSubscription?.unsubscribe();
	}

	private retrieveProducts = () => {
		this.productsSubscription = this.wishlistService.getSavedProductIds()
			.pipe(
				mergeMap(data => this.isWishlist(data) ? of(data.products) : this.productService.getProductsByIds(data)),
			)
			.subscribe({ next: products => this.products = products });
	}

	private isWishlist = (data: number[] | Wishlist): data is Wishlist => {
		return (<Wishlist>data).id !== undefined;
	}
}
