import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from "@angular/forms";
import { Country } from "../../models/country";
import { Province } from "../../models/province";
import { CartService } from "../../../services/cart.service";
import { mergeMap } from "rxjs";
import { CartCosts } from "../../../models/cart-costs";
import { CouponForm } from "../../../shared-components/models/coupon-form";
import { CountryService } from "../../service/country.service";

@Component({
	selector: 'app-cart-details',
	templateUrl: './cart-details.component.html',
	styleUrl: './cart-details.component.css'
})
export class CartDetailsComponent implements OnInit {
	public isEditingAddress: boolean;
	public shippingAddressForm?: FormGroup;
	public countries?: Country[];
	public address?: string;
	public cartCosts?: CartCosts;

	constructor(private cartService: CartService, private countryService: CountryService) {
		this.isEditingAddress = false;
	}

	ngOnInit() {
		this.cartService.getDataChanges()
			.pipe(
				mergeMap(_ => this.cartService.getCartCost())
			)
			.subscribe({
				next: cartCosts => this.cartCosts = cartCosts
			});

		this.countryService.getAllCountries()
			.pipe(
				mergeMap(countries => {
					this.countries = countries;
					const defaultCountry = countries.find(country => country.default) ?? countries[0];
					const defaultProvince = countries.flatMap(country => country.provinces).find(province => province?.default) ?? null;

					this.shippingAddressForm = new FormGroup({
						selectedCountry: new FormControl<Country | null>(defaultCountry),
						selectedZipCode: new FormControl<string | null>(null),
						selectedCity: new FormControl<string | null>(null),
						selectedProvince: new FormControl<Province | null>(defaultProvince),
					});

					this.buildAddress();

					this.cartService.setShipping(defaultCountry.shipping);
					return this.cartService.getCartCost();
				})
			)
			.subscribe({
				next: cartCosts => this.cartCosts = cartCosts
			});
	}

	public showModal = (): void => {
		this.isEditingAddress = !this.isEditingAddress;
	}

	public updateAddress = () => {
		this.showModal();
		this.buildAddress();
	}

	public couponChanged = (event: CouponForm | undefined) => {
		if (this.cartCosts) {

			if (event === undefined) {
				this.cartCosts.total += this.cartCosts.couponForm?.couponResponse.discountedAmount ?? 0;
			} else {
				this.cartCosts.total -= event.couponResponse.discountedAmount ?? 0;
			}

			this.cartCosts.couponForm = event;
		}
	}

	public getFormControlValue = (formControlName: string): any => {
		return this.shippingAddressForm?.get(formControlName)?.value;
	}

	private buildAddress = (): void => {
		if (this.shippingAddressForm) {
			const zipCode = this.shippingAddressForm.get('selectedZipCode')?.value;
			const city = this.shippingAddressForm.get('selectedCity')?.value;
			const province = (this.shippingAddressForm.get('selectedProvince')?.value as Province)?.name;
			const country = (this.shippingAddressForm.get('selectedCountry')?.value as Country)?.name;

			this.address = [zipCode, city, province, country].filter(Boolean).join(', ');
		}
	}
}
