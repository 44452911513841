import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { PaymentIntent } from "../models/payment-intent";

@Injectable({
	providedIn: 'root'
})
export class PaymentService {
	constructor(private httpClient: HttpClient) {
	}

	public getPayment = (id: string): Observable<any> => {
		return this.httpClient.get<any>(`${ environment.apiUrl }/payment/${ id }`);
	}

	public createPaymentIntent = (paymentIntent: PaymentIntent): Observable<any> => {
		return this.httpClient.post(`${ environment.apiUrl }/payment/create-intent`, paymentIntent);
	}
}
