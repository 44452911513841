@if (product) {
	<div class="content-wrapper">
		@if (product.images && product.images[0]) {
			<div class="image-wrapper">
				<img class="product-image" [src]="product.images[0].imageUrl" [alt]="product.name">
			</div>
		}

		<div class="product-details">
			@if (tree$ | async; as tree) {
				<p-breadcrumb class="" [model]="tree" [home]="home"/>
			}
			<h1 class="product-name">{{ product.code }} {{ product.name }}</h1>

			<div class="costs">
				<span class="list-price" [ngClass]="{ 'discounted': product.salePrice }">
					@if (product.attributes) {
						{{ getProductMinPrice(product) | currency:'EUR' }} - {{ getProductMaxPrice(product) | currency:'EUR' }}
					} @else {
						{{ product.listPrice | currency:'EUR' }}
					}
				</span>

				<span class="sale-price">{{ product.salePrice | currency:'EUR' }}</span>
			</div>

			<span class="brief-description">{{ product.briefDescription }}</span>

			@if (product.attributes) {
				<div class="attribute-label">Prezzo: </div>
				<div class="dropdown-wrapper">
					<p-dropdown
							[options]="product.attributes"
							optionLabel="value"
							placeholder="Scegli un'opzione"
							styleClass="attributes-dropdown"/>
				</div>
			} @else {
				<div class="product-status"
					 [ngClass]="{ 'out-of-stock': product.stock === 0, 'in-stock': product.stock > 0 }">
					{{ product.stock > 0 ? 'Disponibile' : 'Esaurito' }}
				</div>

				@if (false) {
					<div class="product-status"
						 [ngClass]="{ 'out-of-stock': product!.stock === 0, 'in-stock': product!.stock > 0 }">
						{{ getStockLabel(product!) }}
					</div>
				}
			}

			@if (product.stock > 1) {
				<app-updown [maximum]="product.stock" [(value)]="itemQuantity"/>
			}

			@if (product.stock > 0) {
				<div class="buttons">
					<app-buy-button [cartProduct]="getCartProduct(product)" [buyDirectly]="false"/>
					<app-buy-button [cartProduct]="getCartProduct(product)" [buyDirectly]="true"/>
				</div>

				<div class="wishlist" (click)="addToWishlist($event, product)">
					<i [class]="'pi pi-heart' + (isAlreadyAdded ? '-fill' : '')"></i>
					Add to wishlist
				</div>
			} @else {
				<div class="notify-me">
					<app-notify-me [product]="product"/>
				</div>
			}
		</div>
	</div>
}