<div class="products-wrapper">
    @for (cartProduct of cartProducts; track cartProduct.product.id) {
        <div class="product">
            @if (cartProduct.product.images && cartProduct.product.images[0]) {
                <div class="image-container">
                    <img class="product-logo" [src]="cartProduct.product.images[0].imageUrl" [alt]="cartProduct.product.name">
                </div>
            }

            <div class="product-details">
                <span class="name">{{ cartProduct.product.name }}</span>

                @if (cartProduct.product.salePrice) {
                    <del class="list-price">{{ cartProduct.product.listPrice | currency:'EUR' }}</del>

                    <div class="sale">
                        SALVA {{ (cartProduct.product.listPrice - cartProduct.product.salePrice) | currency:'EUR' }}
                    </div>
                }

                <app-updown [(value)]="cartProduct.quantity" [minimum]="1" [maximum]="cartProduct.product.stock" (increase)="editQuantity(cartProduct, ActionType.INCREASE)" (decrease)="editQuantity(cartProduct, ActionType.DECREASE)" />

                <span class="remove-product" (click)="removeProduct(cartProduct.product)">Rimuovi articolo</span>
            </div>

            <div class="product-price">
                <span>{{ ((cartProduct.product.salePrice ? cartProduct.product.salePrice : cartProduct.product.listPrice) * cartProduct.quantity) | currency:'EUR' }}</span>
            </div>
        </div>

        <app-header-divider lineHeight="1px" color="rgba(255, 255, 255, 0.5)"/>
    }
</div>