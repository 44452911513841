import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomepageComponent } from "./homepage/view/homepage/homepage.component";
import { CategoryComponent } from "./category/components/category/category.component";
import { SubcategoryComponent } from "./category/components/subcategory/subcategory.component";
import { ProductsComponent } from "./category/components/products/products.component";
import { WishlistComponent } from "./header/views/wishlist/wishlist.component";
import { CartComponent } from "./cart/views/cart/cart.component";
import { CheckoutComponent } from "./cart/views/checkout/checkout.component";
import { PaymentStatusComponent } from "./cart/components/payment-status/payment-status.component";
import { ProductDetailsComponent } from "./product/components/product-details/product-details.component";

const routes: Routes = [
	{ path: '', pathMatch: 'full', component: HomepageComponent },
	{ path: 'category/:category_path', component: CategoryComponent },
	{ path: 'category/:category_path/:subcategory_path', component: SubcategoryComponent },
	{ path: 'category/:category_path/:subcategory_path/:category_last_subpath/products', component: ProductsComponent },
	{ path: 'product/:product_id/:product_name', component: ProductDetailsComponent },
	{ path: 'wishlist', component: WishlistComponent },
	{ path: 'cart', component: CartComponent },
	{ path: 'checkout', component: CheckoutComponent },
	{ path: 'payment-status', component: PaymentStatusComponent },
	{ path: 'search', component: ProductsComponent },
	{
		path: 'auth',
		loadChildren: () => import('./authentication/authentication-routing.module').then(m => m.AuthenticationRoutingModule)
	},
	{
		path: 'account',
		loadChildren: () => import('./account/account-routing.module').then(m => m.AccountRoutingModule)
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
