<div class="form-wrapper">
	<form [formGroup]="formGroup">
		<div class="step">
			<div class="number-description">
				<div class="number">1.</div>
				<div class="step-description">Informazioni di contatto</div>
			</div>
			<div class="content">
				<div class="email-wrapper">
                            <span class="panel-info">
                                Utilizzeremo questa email per inviare i dettagli e gli aggiornamenti relativi al tuo ordine.
                            </span>
					<app-form-field label="Indirizzo email" labelId="email"
									[control]="getFormControl('email')"
									errorClass="email"
									[errorMessage]="ErrorMessage.VALID_EMAIL"/>
				</div>
			</div>
		</div>
		<div class="step">
			<div class="number-description">
				<div class="number">2.</div>
				<div class="step-description">Indirizzo di spedizione</div>
			</div>
			<div class="content">
				<app-form-address-section
						description="Inserisci l'indirizzo al quale vuoi che il tuo ordine venga consegnato."
						[formGroup]="formGroup" [countries]="countries"/>

				<div class="billing-address-check">
					<label>
						<p-checkbox
								formControlName="sameAddress"
								[binary]="true"
								id="billing-address"/>
						Usa lo stesso indirizzo per la fatturazione
					</label>
				</div>
			</div>
		</div>

		@if (!isSameBillingAddress()) {
			<div class="step">
				<div class="number-description">
					<div class="number">3.</div>
					<div class="step-description">Indirizzo di fatturazione</div>
				</div>
				<div class="content">
					<app-form-address-section
							description="Inserisci l'indirizzo di fatturazione che corrisponde al tuo metodo di pagamento."
							[formGroup]="formGroup"
							[isBilling]="true"
							[countries]="countries"/>
				</div>
			</div>
		}

		<div class="step">
			<div class="number-description">
				<div class="number">{{ isSameBillingAddress() ? '3.' : '4.' }}</div>
				<div class="step-description">Opzioni di pagamento</div>
			</div>
			<div class="content payment">
				<app-payment [formGroup]="formGroup" />
			</div>
		</div>

		<div class="footer">
			<div class="note-wrapper">
				<div class="enable-note">
					<p-checkbox
							[(ngModel)]="showNote"
							[ngModelOptions]="{standalone: true}"
							[binary]="true"
							id="order-note"/>
					<label for="order-note">Aggiungi una nota all'ordine</label>
				</div>
				<textarea class="note-input" rows="3" cols="5" pInputTextarea [hidden]="!showNote"
						  formControlName="note" [autoResize]="true"
						  placeholder="Note sull'ordine, ad esempio richieste particolari per la consegna.">
				</textarea>
			</div>
			<span class="privacy">Per procedere con il tuo acquisto, accetta Termini e condizioni e Informativa sulla privacy</span>

			<app-header-divider lineHeight="1px" color="rgba(255, 255, 255, .5)"/>

			<div class="actions-wrapper">
				<p-button styleClass="back-button" routerLink="/cart">
					<i class="pi pi-arrow-left"></i>
					Torna al carrello
				</p-button>
				<p-button styleClass="proceed-button" (click)="paymentProceed()">EFFETTUA ORDINE</p-button>
			</div>
		</div>
	</form>
</div>