@if (countries) {
	<form [formGroup]="formGroup">
		<div class="personal-data">
			<span class="panel-info">{{ description }}</span>
			<div class="row">
				<app-form-field class="form-field"
								label="Nome" [labelId]="isBilling ? 'billing-first-name' : 'first-name'"
								[control]="getFormControl('firstName')"
								[errorClass]="isBilling ? 'billing-first-name' : 'first-name'"
								[errorMessage]="ErrorMessage.FIELD_NOT_EMPTY"/>
				<app-form-field class="form-field"
								label="Cognome" [labelId]="isBilling ? 'billing-last-name' : 'last-name'"
								[control]="getFormControl('lastName')"
								[errorClass]="isBilling ? 'billing-last-name' : 'last-name'"
								[errorMessage]="ErrorMessage.FIELD_NOT_EMPTY"/>
			</div>

			<app-form-field class="form-field"
							label="Indirizzo" [labelId]="isBilling ? 'billing-address' : 'address'"
							[control]="getFormControl('address')"
							[errorClass]="isBilling ? 'billing-address' : 'address'"
							[errorMessage]="ErrorMessage.FIELD_NOT_EMPTY"/>

			<p-dropdown
					[formControlName]="isBilling ? 'billingCountry' : 'country'"
					[options]="countries"
					optionLabel="name"
					placeholder="Paese/Regione"
					filter="true"
					filterBy="name"
					styleClass="dropdown-wrapper"/>

			<div class="row">
				<app-form-field class="form-field"
								label="CAP" [labelId]="isBilling ? 'billing-zip-code' : 'zip-code'"
								[control]="getFormControl('zipCode')"
								[errorClass]="isBilling ? 'billing-zip-code' : 'zip-code'"
								[errorMessage]="getZipCodeErrors()"/>

				<app-form-field class="form-field"
								label="Città" [labelId]="isBilling ? 'billing-city' : 'city'"
								[control]="getFormControl('city')"
								[errorClass]="isBilling ? 'billing-city' : 'city'"
								[errorMessage]="ErrorMessage.FIELD_NOT_EMPTY"/>
			</div>

			<div class="row country">
				@if (getFormControlValue('country').provinces) {
					<p-dropdown
							[formControlName]="isBilling ? 'billingProvince' : 'province'"
							[options]="getFormControlValue('country').provinces"
							optionLabel="name"
							placeholder="Provincia"
							filter="true"
							filterBy="name"
							styleClass="dropdown-wrapper"/>
				}

				<app-form-field class="form-field"
								label="Telefono (facoltativo)" [labelId]="isBilling ? 'billing-phone' : 'phone'"
								[control]="getFormControl('phone')"
								[errorClass]="isBilling ? 'billing-phone' : 'phone'"
								[errorMessage]="ErrorMessage.VALID_PHONE"/>
			</div>
		</div>
	</form>
}