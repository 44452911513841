import { Component, OnDestroy, OnInit } from '@angular/core';
import { CartProduct } from "../../models/cart-product";
import { Product } from "../../../shared-components/models/product";
import { CartService } from "../../../services/cart.service";
import { ActionType } from "../../../models/enumeration/action-type";
import { Subscription } from "rxjs";

@Component({
	selector: 'app-cart-products',
	templateUrl: './cart-products.component.html',
	styleUrl: './cart-products.component.css'
})
export class CartProductsComponent implements OnInit, OnDestroy {
	public cartProducts?: CartProduct[];

	private retrieveSubscription?: Subscription;
	private removeSubscription?: Subscription;
	private updateSubscription?: Subscription;

	constructor(private cartService: CartService) {
	}

	ngOnInit(): void {
		this.retrieveSubscription = this.cartService.getSavedProducts()
			.subscribe({
				next: data => this.cartProducts = data
			});
	}

	ngOnDestroy(): void {
		this.retrieveSubscription?.unsubscribe();
		this.removeSubscription?.unsubscribe();
		this.updateSubscription?.unsubscribe();
	}

	public removeProduct = (product: Product) => {
		this.removeSubscription = this.cartService.removeProduct(product)
			.subscribe();
		this.cartProducts = this.cartProducts?.filter(cartProduct => cartProduct.product.id !== product.id);
	}

	public editQuantity = (cartProduct: CartProduct, action: ActionType) => {
		this.updateSubscription = this.cartService.editProductQuantity(cartProduct, action)
			.subscribe();
	}

	protected readonly ActionType = ActionType;
}
