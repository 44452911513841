<div class="container">
    <h4 class="title">YOUR PRODUCTS WISHLIST</h4>
    @if (products?.length ?? 0 > 0) {
        <div class="products-wrapper">
            @for (product of products; track product.id) {
                <app-product-card [product]="product"/>
            }
        </div>
    } @else {
        <div class="empty-content">
            <i class="pi pi-heart icon"></i>
            <h1>This wishlist is empty</h1>
            <h4 class="description">Non hai inserito Prodotti nella tua Wishlist</h4>
            <p-button routerLink="/" styleClass="return-button">RETURN TO SHOP</p-button>
        </div>
    }
</div>