import { Component } from '@angular/core';
import { DeviceDetectorService } from "ngx-device-detector";
import { AuthService } from "../../../services/auth.service";

@Component({
	selector: 'app-top-header',
	templateUrl: './top-header.component.html',
	styleUrl: './top-header.component.css'
})
export class TopHeaderComponent {

	constructor(public deviceService: DeviceDetectorService, public authService: AuthService) {
	}

}
