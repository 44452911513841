import { Component, Input, OnDestroy } from '@angular/core';
import { CartProduct } from "../../../cart/models/cart-product";
import { CartService } from "../../../services/cart.service";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";

@Component({
	selector: 'app-buy-button',
	templateUrl: './buy-button.component.html',
	styleUrl: './buy-button.component.css'
})
export class BuyButtonComponent implements OnDestroy {
	@Input() cartProduct?: CartProduct;
	@Input() buyDirectly?: boolean;

	private cartSubscription?: Subscription;

	constructor(private cartService: CartService, private router: Router) {
	}

	ngOnDestroy() {
		this.cartSubscription?.unsubscribe();
	}

	public buyNow = (): void => {
		if (this.cartProduct) {
			this.cartSubscription = this.cartService.storeCartProduct(this.cartProduct)
				.subscribe();

			if (this.buyDirectly === true) {
				this.router.navigate(['/checkout']);
			}
		}
	}
}
