import { Component, OnDestroy, OnInit } from '@angular/core';
import { CartProduct } from "../../../cart/models/cart-product";
import { ActionType } from "../../../models/enumeration/action-type";
import { Router } from "@angular/router";
import { CartService } from "../../../services/cart.service";
import { CartCosts } from "../../../models/cart-costs";
import { Observable, Subscription } from "rxjs";

@Component({
	selector: 'app-cart-sidebar',
	templateUrl: './cart-sidebar.component.html',
	styleUrl: './cart-sidebar.component.css'
})
export class CartSidebarComponent implements OnInit, OnDestroy {
	public isSidebarVisible: boolean;
	public cartCosts$?: Observable<CartCosts>;
	public cartProducts?: CartProduct[];

	private cartProductsSubscription?: Subscription;

	constructor(
		private cartService: CartService,
		private router: Router
	) {
		this.isSidebarVisible = false;
	}

	ngOnInit() {
		this.loadProducts();

		this.cartService.getDataChanges()
			.subscribe({
				next: _ => this.loadProducts()
			});

		this.cartService.cartToggled()
			.subscribe({
				next: _ => this.isSidebarVisible = !this.isSidebarVisible
			});
	}

	ngOnDestroy() {
		this.cartProductsSubscription?.unsubscribe();
	}

	public increaseQuantity = (cartProduct: CartProduct) => {
		this.cartService.editProductQuantity(cartProduct, ActionType.INCREASE)
			.subscribe();
	}

	public decreaseQuantity = (cartProduct: CartProduct): void => {
		if (cartProduct.quantity === 0) {
			this.cartService.removeProduct(cartProduct.product)
				.subscribe();
		} else {
			this.cartService.editProductQuantity(cartProduct, ActionType.DECREASE)
				.subscribe();
		}
	}

	private loadProducts = () => {
		this.cartCosts$ = this.cartService.getCartCost();
		this.cartProductsSubscription = this.cartService.getSavedProducts()
			.subscribe({
				next: cartProducts => this.cartProducts = cartProducts
			});
	}

	public closeSidebarAndNavigate = (url: string): void => {
		this.router.navigate([url])
			.then(_ => this.isSidebarVisible = false);
	}
}
