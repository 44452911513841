import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from "@angular/core";
import { KeycloakService } from "keycloak-angular";
import { StorageService } from "../storage.service";
import { StorageKey } from "../../models/enumeration/storage-key";
import { mergeMap, of } from "rxjs";
import { AuthResponse } from "../../models/auth-response";
import { AuthService } from "../auth.service";
import { Router } from "@angular/router";

export const authInterceptor: HttpInterceptorFn = (req, next) => {
	if (isWhitelisted(req.url)) {
		return next(req);
	}

	const isUserLoggedFromIdp = inject(KeycloakService).isLoggedIn();

	if (isUserLoggedFromIdp) {
		return next(req);
	}

	const router = inject(Router);
	const storageService = inject(StorageService);
	const tokenData = storageService.get<AuthResponse>(StorageKey.AUTH);

	if (!tokenData) {
		storageService.remove(StorageKey.AUTH);
		router.navigate(['/auth/login']);
		return of();
	}

	const authService = inject(AuthService);
	let authRequest = req.clone({
		headers: req.headers.set('Authorization', `${ tokenData.tokenType } ${ tokenData.accessToken }`)
	});

	if (!authService.isAccessTokenValid()) {
		if (!authService.isRefreshTokenValid()) {
			storageService.remove(StorageKey.AUTH);
			router.navigate(['/auth/login']);
			return of();
		}

		return authService.refreshToken(tokenData.refreshToken)
			.pipe(
				mergeMap(refreshToken => {
					storageService.store(StorageKey.AUTH, refreshToken);
					authRequest = req.clone({
						headers: req.headers.set('Authorization', `${ refreshToken.tokenType } ${ refreshToken.accessToken }`)
					});

					return next(req);
				})
			);
	}

	return next(authRequest);
};

const isWhitelisted = (url: string): boolean => {
	const whitelistUrls = ["/orders", "/wishlist", "/cart"];

	for (const whitelistUrl of whitelistUrls) {
		if (url.includes(whitelistUrl)) {
			return false;
		}
	}

	return true;
}