import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderModule } from "./header/header.module";
import { FooterModule } from "./footer/footer.module";
import { CategoryModule } from "./category/category.module";
import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withInterceptors } from '@angular/common/http';
import { SharedModule } from "primeng/api";
import { SharedComponentsModule } from "./shared-components/shared-components.module";
import { HomepageModule } from "./homepage/homepage.module";
import { retryInterceptor } from "./services/interceptors/retry.interceptor";
import { ProductModule } from "./product/product.module";
import { SidebarModule } from "primeng/sidebar";
import { KeycloakAngularModule, KeycloakService } from "keycloak-angular";
import { environment } from "../environments/environment";
import { AuthenticationModule } from "./authentication/authentication.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AccountModule } from "./account/account.module";
import { registerLocaleData } from "@angular/common";
import localeIt from '@angular/common/locales/it';
import localeItExtra from '@angular/common/locales/extra/it';
import { authInterceptor } from "./services/interceptors/auth.interceptor";

const initializeKeycloak = (keycloak: KeycloakService) => {
	return async () => {
		await keycloak.init({
			config: {
				url: `${ environment.keycloak.url }/auth`,
				realm: environment.keycloak.realm,
				clientId: environment.keycloak.clientId
			},
			initOptions: {
				onLoad: 'check-sso',
				silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
				checkLoginIframe: true
			},
			enableBearerInterceptor: true,
			bearerPrefix: 'Bearer'
		});
	}
}

registerLocaleData(localeIt, 'it', localeItExtra);

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		HeaderModule,
		FooterModule,
		CategoryModule,
		HttpClientModule,
		SharedModule,
		SharedComponentsModule,
		HomepageModule,
		ProductModule,
		SidebarModule,
		AuthenticationModule,
		AccountModule,
		KeycloakAngularModule
	],
	bootstrap: [AppComponent],
	providers: [
		provideHttpClient(withInterceptors([retryInterceptor, authInterceptor])),
		{
			provide: APP_INITIALIZER,
			useFactory: initializeKeycloak,
			multi: true,
			deps: [KeycloakService]
		},
		{
			provide: LOCALE_ID,
			useValue: 'it-IT'
		}
	]
})
export class AppModule {
}