import { Injectable } from '@angular/core';
import { mergeMap, Observable, of, Subject, tap } from "rxjs";
import { StorageService } from "./storage.service";
import { StorageKey } from "../models/enumeration/storage-key";
import { AuthService } from "./auth.service";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Wishlist } from "../models/wishlist";

@Injectable({
	providedIn: 'root'
})
export class WishlistService {

	private _wishlist: Subject<number>;

	constructor(
		private storageService: StorageService,
		private authService: AuthService,
		private httpClient: HttpClient
	) {
		this._wishlist = new Subject<number>();
	}

	public getDataChanges = (): Observable<number> => {
		return this._wishlist.asObservable();
	}

	public storeProduct = (productId: number): Observable<void> => {
		return (this.authService.isUserLogged()
			? this.storeProductRemotely(productId)
			: this.storeProductLocally(productId))
			.pipe(
				tap(_ => this.notify(productId))
			);
	}

	public removeProduct = (productId: number): Observable<void> => {
		return (this.authService.isUserLogged()
			? this.removeProductRemotely(productId)
			: this.removeProductLocally(productId))
			.pipe(
				tap(_ => this.notify(productId))
			);
	}

	public getSavedProductIds = (): Observable<number[] | Wishlist> => {
		return this.authService.isUserLogged()
			? this.getSavedProductsRemotely()
			: this.getSavedProductsLocally();
	}

	public isProductAlreadyAdded = (productId?: number): Observable<boolean> => {
		if (!productId) {
			return of(false);
		}

		return this.authService.isUserLogged()
			? this.isProductAlreadyAddedRemotely(productId)
			: this.isProductAlreadyAddedLocally(productId);
	}

	private storeProductLocally = (productId: number): Observable<void> => {
		return this.getSavedProductsLocally()
			.pipe(
				mergeMap(actualProductIds => {
					const productIds = [...actualProductIds, productId];
					this.storageService.store(StorageKey.WISHLIST_PRODUCTS, productIds);

					return of(void 0);
				})
			);
	}

	private storeProductRemotely = (productId: number): Observable<void> => {
		return this.httpClient.post<void>(`${ environment.apiUrl }/wishlist/products`, productId);
	}

	private removeProductLocally = (productId: number): Observable<void> => {
		return this.getSavedProductsLocally()
			.pipe(
				mergeMap(actualProductIds => {
					actualProductIds.splice(actualProductIds.indexOf(productId), 1);
					this.storageService.store(StorageKey.WISHLIST_PRODUCTS, actualProductIds);
					return of(void 0);
				})
			);
	}

	private removeProductRemotely = (productId: number): Observable<void> => {
		return this.httpClient.delete<void>(`${ environment.apiUrl }/wishlist/products/${ productId }`);
	}

	private getSavedProductsLocally = (): Observable<number[]> => {
		return of(this.storageService.get(StorageKey.WISHLIST_PRODUCTS) ?? []);
	}

	private getSavedProductsRemotely = (): Observable<Wishlist> => {
		return this.httpClient.get<Wishlist>(`${ environment.apiUrl }/wishlist`);
	}

	private isProductAlreadyAddedLocally = (productId: number): Observable<boolean> => {
		return this.getSavedProductsLocally()
			.pipe(
				mergeMap(productIds => of(productIds.indexOf(productId) >= 0))
			);
	}

	private isProductAlreadyAddedRemotely = (productId: number): Observable<boolean> => {
		return this.httpClient.get<boolean>(`${ environment.apiUrl }/wishlist/products/${ productId }/exists`);
	}

	private notify = (productId: number) => {
		this._wishlist.next(productId);
	}
}
