import { Component, OnDestroy, OnInit } from '@angular/core';
import { WishlistService } from "../../../services/wishlist.service";
import { mergeMap, Subscription } from "rxjs";
import { Wishlist } from "../../../models/wishlist";
import { Product } from "../../../shared-components/models/product";

@Component({
	selector: 'app-wishlist-menu',
	templateUrl: './wishlist-menu.component.html',
	styleUrl: './wishlist-menu.component.css'
})
export class WishlistMenuComponent implements OnInit, OnDestroy {
	public savedProductIds?: number[] | Product[];
	private productSubscription?: Subscription;
	private dataChangeSubscription?: Subscription;

	constructor(private wishlistService: WishlistService) {
	}

	ngOnInit() {
		this.getSavedProductIds();
		this.dataChangeSubscription = this.wishlistService.getDataChanges()
			.pipe(
				mergeMap(_ => this.wishlistService.getSavedProductIds())
			)
			.subscribe({
				next: data => {
					this.savedProductIds = this.isWishlist(data) ? data.products : data;
				}
			});
	}

	ngOnDestroy() {
		this.productSubscription?.unsubscribe();
		this.dataChangeSubscription?.unsubscribe();
	}

	private getSavedProductIds = (): void => {
		this.productSubscription = this.wishlistService.getSavedProductIds()
			.subscribe({
				next: data => this.savedProductIds = this.isWishlist(data) ? data.products : data
			});
	}

	private isWishlist = (data: number[] | Wishlist): data is Wishlist => {
		return (<Wishlist>data).id !== undefined;
	}
}
