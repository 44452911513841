@if (cartCosts && cartProducts) {
	<app-header-divider lineHeight="1px" color="rgba(255, 255, 255, .5)"/>

	<div class="order-recap">
		<p-accordion [activeIndex]="0">
			<p-accordionTab header="Riepilogo dell'ordine">
				<div class="products">
					@for (cartProduct of cartProducts; track cartProduct.product.id; let index = $index) {
						<div class="product-wrapper default-font">
							@if (cartProduct.product.images && cartProduct.product.images[0]) {
								<div class="image-wrapper">
									<img class="product-image" [src]="cartProduct.product.images[0].imageUrl"
										 [alt]="cartProduct.product.name">
								</div>
							}

							<div class="product-data">
								<span class="default-font">{{ cartProduct.product.name }}</span>

								<div class="cart-info">
									<div class="price">
                                    <span class="list-price"
										  [ngClass]="{strike: cartProduct.product.salePrice, discounted: cartProduct.product.salePrice}">
                                        {{ cartProduct.product.listPrice | currency:'EUR' }}
                                    </span>
										<span class="sale-price">{{ cartProduct.product.salePrice | currency:'EUR' }}</span>
									</div>

									<div class="quantity">
										<span class="default-font">Quantity: {{ cartProduct.quantity }}</span>
									</div>
								</div>
							</div>
						</div>
						<div class="stock">
                        <span class="label">
<!--							TODO: SOLO ADMIN PUÒ VEDERLO -->
							{{ cartProduct.product.stock }} {{ cartProduct.product.stock > 1 ? 'PEZZI RIMASTI' : 'PEZZO RIMASTO' }}
							IN MAGAZZINO
                        </span>
						</div>

						@if (index < cartProducts.length - 1) {
							<app-header-divider lineHeight="1px" color="rgba(255, 255, 255, .5)"/>
						}
					}
				</div>
			</p-accordionTab>
		</p-accordion>

		<app-header-divider lineHeight="1px" color="rgba(255, 255, 255, .5)"/>

		<div class="coupon-code">
			<app-coupon (couponChange)="couponChanged($event)"/>
		</div>

		<app-header-divider lineHeight="1px" color="rgba(255, 255, 255, .5)"/>

		<div class="sub-total">
			<span class="label default-font">Subtotale</span>
			<span class="amount default-font">{{ cartCosts.subtotal | currency:'EUR' }}</span>
		</div>

		@if (cartCosts.couponForm !== undefined) {
			<app-header-divider class="divider" color="rgba(255, 255, 255, 0.5)" lineHeight="1px"/>

			<div class="discount default-font">
				<span>Sconto</span>
				<span class="value">- {{ cartCosts.couponForm.couponResponse.discountedAmount | currency:'EUR' }}</span>
			</div>
		}

		<app-header-divider lineHeight="1px" color="rgba(255, 255, 255, .5)"/>

		<div class="shipping">
			<div class="shipping-wrapper">
				<div class="labels">
					<span class="label default-font">Spedizione</span>
					<div class="shipping-operator">
						<span class="label default-font">{{ cartCosts.shippingCompany }}</span>
						@if (!cartCosts.shippingCost) {
							<span class="label default-font"> (Spedizione gratuita)</span>
						}
					</div>
				</div>

				<span class="shipping-cost default-font">{{ cartCosts.shippingCost ?? 0 | currency:'EUR' }}</span>
			</div>

			<span class="default-font">Spedizione a {{ getShippingAddress() }}</span>
		</div>

		<app-header-divider lineHeight="1px" color="rgba(255, 255, 255, .5)"/>

		<div class="total">
			<span class="label default-font">Totale</span>
			<span class="amount default-font">{{ cartCosts.total | currency:'EUR' }}</span>
		</div>
	</div>
}