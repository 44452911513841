import { Component, Input } from '@angular/core';
import { ErrorMessage } from "../../models/enumeration/error-message";
import { FormControl, FormGroup } from "@angular/forms";
import { Country } from "../../../cart/models/country";
import { CountryService } from "../../../cart/service/country.service";
import { Observable } from "rxjs";

@Component({
	selector: 'app-form-address-section',
	templateUrl: './form-address-section.component.html',
	styleUrl: './form-address-section.component.css'
})
export class FormAddressSectionComponent {
	@Input() formGroup!: FormGroup;
	@Input() description?: string;
	@Input() isBilling?: boolean;
	@Input() countries?: Country[];

	constructor() {
	}

	public getFormControl = (formControlName: string): FormControl => {
		return this.formGroup.get(this.isBilling ? `billing${ this.capitalizeString(formControlName) }` : formControlName) as FormControl;
	}

	public getFormControlValue = (formControlName: string): any => {
		return this.formGroup.get(this.isBilling ? `billing${ this.capitalizeString(formControlName) }` : formControlName)?.value;
	}

	public getZipCodeErrors = (): string => {
		const errors = this.getFormControl('zipCode').errors;
		return (errors?.['required'] === true) ? ErrorMessage.FIELD_NOT_EMPTY : ErrorMessage.VALID_ZIP_CODE;
	}

	private capitalizeString = (value: string): string => {
		return value.charAt(0).toUpperCase() + value.slice(1);
	}

	protected readonly ErrorMessage = ErrorMessage;
}
