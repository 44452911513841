import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Country } from "../../models/country";
import { Province } from "../../models/province";
import { FormGroup } from "@angular/forms";
import { CartProduct } from "../../models/cart-product";
import { CartService } from "../../../services/cart.service";
import { mergeMap, of, Subscription } from "rxjs";
import { CartCosts } from "../../../models/cart-costs";
import { CouponForm } from "../../../shared-components/models/coupon-form";

@Component({
	selector: 'app-order-recap',
	templateUrl: './order-recap.component.html',
	styleUrl: './order-recap.component.css'
})
export class OrderRecapComponent implements OnInit, OnDestroy {
	@Input() formGroup!: FormGroup;
	@Input() cartProducts?: CartProduct[];

	public cartCosts?: CartCosts;

	private subscription?: Subscription;

	constructor(private cartService: CartService) {
	}

	ngOnInit() {
		this.subscription = of(this.formGroup.get('country')?.value)
			.pipe(
				mergeMap(country => {
					this.cartService.setShipping(country.shipping);
					return this.cartService.getCartCost();
				})
			)
			.subscribe({
				next: cartCosts => this.cartCosts = cartCosts
			});
	}

	ngOnDestroy() {
		this.subscription?.unsubscribe();
	}

	public getShippingAddress = (): string => {
		const zipCode: string = this.formGroup.get('zipCode')?.value;
		const city: string = this.formGroup.get('city')?.value;
		const country: Country = this.formGroup.get('country')?.value;
		const province: Province = this.formGroup.get('province')?.value;

		return [zipCode, city, province.name, country.name].filter(Boolean).join(', ');
	}

	public couponChanged = (event: CouponForm | undefined) => {
		if (this.cartCosts) {

			if (event === undefined) {
				this.cartCosts.total += this.cartCosts.couponForm?.couponResponse.discountedAmount ?? 0;
			} else {
				this.cartCosts.total -= event.couponResponse.discountedAmount ?? 0;
			}

			this.cartCosts.couponForm = event;
		}
	}
}
