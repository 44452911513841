import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignInComponent } from './views/sign-in/sign-in.component';
import { SharedComponentsModule } from "../shared-components/shared-components.module";
import { PostAuthComponent } from './views/post-auth/post-auth.component';
import { LogoutComponent } from './views/logout/logout.component';
import { AuthComponent } from './views/auth/auth.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';


@NgModule({
	declarations: [
		SignInComponent,
		PostAuthComponent,
		LogoutComponent,
		AuthComponent,
		SignUpComponent
	],
	imports: [
		CommonModule,
		SharedComponentsModule
	]
})
export class AuthenticationModule {
}
