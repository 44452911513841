@if (isLoading) {
	<app-loading/>
}

<div class="container" [hidden]="isLoading">
	<app-top-header class="sticky top-0 z-5" [hidden]="shouldShowHeaderAndFooter"/>
	<app-header [hidden]="shouldShowHeaderAndFooter"/>
	<div class="router-wrapper">
		<router-outlet/>
		<app-cart-sidebar/>
	</div>
	<app-footer [hidden]="shouldShowHeaderAndFooter"/>
</div>